import React, {Fragment} from 'react';
import Arrow from '../arrow/Arrow';
import {Element} from 'react-scroll';

import './Pane.css'

export default () => {

    return (
        <Fragment>
            <div />
            <Element name="dayTwo"/>
            <div className='pane'>
                <Arrow direction='up' scrollElement='dayOne'/>
                <div className='container'>
                    <div className='dayHeader bold'>SATURDAY, AUGUST 10, 2019</div>
                    <div><hr/></div>
                    <div className='day-organizer'>
                        <div >
                            <div className='organizer-left bold'>04:00 PM</div>
                            <div className='organizer-right bold'>Cocktail Hour</div>
                        </div>
                        <div >
                            <div className='organizer-left bold'>05:00 PM</div>
                            <div className='organizer-right bold'>Ceremony</div>
                        </div>
                        <div >
                            <div className='organizer-left bold'>06:00 PM</div>
                            <div className='organizer-right bold'>Dinner</div>
                        </div>
                        <div >
                            <div className='organizer-left bold'>07:00 PM</div>
                            <div className='organizer-right bold'>Dancing</div>
                        </div>
                        <div >
                            <div className='organizer-left bold'>12:00 PM</div>
                            <div className='organizer-right bold'>Fin!</div>
                        </div>
                    </div>
                </div>
                <Arrow direction='down' scrollElement='dayThree'/>
            </div>
        </Fragment>)
}