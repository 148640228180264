import React, {useState, useEffect} from 'react';

import './countdown.css'

export default ({date = new Date("Jan 5, 2021 15:37:25")}) => {

    const countDownDate = date;

    const [seconds, setSeconds] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [hours, setHours] = useState(0);
    const [days, setDays] = useState(0);

    const clock = () => {

        setInterval(function() {

            // Get todays date and time
            const now = new Date().getTime();

            // Find the distance between now and the count down date
            const distance = countDownDate - now;

            // Time calculations for days, hours, minutes and seconds
            setDays(Math.floor(distance / (1000 * 60 * 60 * 24)));
            setHours(Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
            setMinutes(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)));
            setSeconds(Math.floor((distance % (1000 * 60)) / 1000));

        }, 1000);
    };

    useEffect(() => {
        clock();
    });

    return(
        <div className='countdown'>
            <div className="days">
                <div className='value'><div className='right'>{days}</div></div>
                <div className='time-key'><div className='left'>DAYS</div></div>
            </div>
            <div className="hours">
                <div className='value'><div className='right'>{hours}</div></div>
                <div className='time-key'><div className='left'>HRS</div></div>
            </div>
            <div className="minutes">
                <div className='value'><div className='right'>{minutes}</div></div>
                <div className='time-key'><div className='left'>MINS</div></div>
            </div>
            <div className="seconds">
                <div className='value'><div className='right'>{seconds}</div></div>
                <div className='time-key'><div className='left'>SECS</div></div>
            </div>
        </div>
    )

}