import React, {createContext, useReducer} from 'react';

export const Store = createContext();

const initialState = {
    ChildPane: 'HOME'
};

function reducer(state, action) {
    console.log('state', state);
    debugger;
    if(action.type === 'CHANGE-PANE'){
        return {...state, ChildPane: action.payload }
    }
    return state;
}

export function StoreProvider(props) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const value = { state, dispatch };
    return <Store.Provider value={value}>{props.children}</Store.Provider>;
}