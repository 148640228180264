import React, {Fragment} from 'react';
import Arrow from '../arrow/Arrow'
import {Element} from 'react-scroll'

import './Pane.css'

export default () => {

    return (
        <Fragment>
            <div />
            <Element name="dayOne"/>
            <div id='dayOne' className='pane'>
                <Arrow direction='up' scrollElement='details'/>
                <div className='container'>
                    <div className='dayHeader bold'>FRIDAY, AUGUST 09, 2019</div>
                    <div><hr/></div>
                    <div className='day-organizer'>
                        <div >
                            <div className='organizer-left bold'>05:00 PM</div>
                            <div className='organizer-right bold'>Rehearsal</div>
                        </div>
                        <div >
                            <div className='organizer-left bold'>06:00 PM</div>
                            <div className='organizer-right bold'>Welcome Luau</div>
                        </div>
                        <div>
                            <div className='organizer-left'/>
                            <div className='organizer-right dayTextSmall'>
                                By invitation only. Please email kleinbs1234@gmail.com for inquires and RSVP.
                            </div>
                        </div>
                    </div>
                </div>
                <Arrow direction='down' scrollElement='dayTwo'/>
            </div>
        </Fragment>)
}