import React from 'react';

import './LeftContainer.css'

import Countdown from '../../components/countdown/countdown'

export default () => {
    const weddingDate = new Date("Aug 10, 2019 16:00:00");

    return (
        <div id='left-container'>
            <div id='left-content'>
                <Countdown date={weddingDate}/>
            </div>
        </div>
    )
}