import React, {Fragment} from 'react';

import LeftContainer from './left-container/LeftContainer'
import RightContainer from './right-container/RightContainer'
import RegContainer from './reg-container'

import './App.css'

export default () => {



  return (
      <Fragment>
          <LeftContainer/>
          <RightContainer/>
      </Fragment>
      )
};
