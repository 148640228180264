import React, {Fragment} from 'react';
import Arrow from '../arrow/Arrow'
import {Element} from 'react-scroll';

import './Pane.css'
import './registryStyles.css'

export default () => {

    return (
        <Fragment>
            <div/>
            <Element name="registry"/>
            <div id='details' className='pane'>
                <Arrow direction={'up'} scrollElement={'dayThree'} />
                <div className='container'>
                    <div className='dayHeader bold'>Registry</div>
                    <div><hr/></div>
                    <div id='location'>
                        <div style={{width:'200px', margin:'0 auto'}}>
                            <a href="http://www.honeyfund.com/wedding/MonicaLovesBrian4ever"
                               style={{'text-align':'left',background:"url('http://www.honeyfund.com/share/honeyfund3.png') no-repeat", display:'block', width:'200px',height:'100px','text-indent':'-9999em'}}>Visit our honeyfund at Honeyfund.com, the free honeymoon registry</a>
                        </div>
                        <div style={{width:'200px', margin:'0 auto'}}>
                            <a id={'amazon'} href="https://www.amazon.com/wedding/share/brianandmonicaforever" />
                        </div>
                        <div style={{width:'200px', margin:'0 auto'}}>
                            <a id={'target'} href="https://www.target.com/gift-registry/giftgiver?registryId=f43e656239304aa68440752f1894fe1b&lnk=registry_custom_url" />
                        </div>
                        <div style={{width:'200px', margin:'0 auto'}}>
                            <a id={'pottery-barn'} href="https://www.potterybarn.com/registry/xs6grlsv77/registry-list.html" />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}