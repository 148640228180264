import React, {Fragment} from 'react';
import {Element} from 'react-scroll'

import Arrow from '../arrow/Arrow'

import './Pane.css'

export default () => {

    return (
        <Fragment>
            <Element name="home"/>
            <div id='home' className='pane'>
                <div className='heart'>Y</div>
                <h1>WELCOME TO THE WEDDING PAGE OF</h1>
                <h2 className='lucky-couple'>BRIAN & MONICA</h2>
                <Arrow direction={'down'} scrollElement={'details'}/>
            </div>
        </Fragment>
        )
}