import React from 'react';

import HomePane from '../../components/InfoPanes/HomePane'
import DetailsPane from '../../components/InfoPanes/DetailsPane'
import DayOne from '../../components/InfoPanes/DayOne'
import DayTwo from '../../components/InfoPanes/DayTwo'
import DayThree from '../../components/InfoPanes/DayThree'
import RegistryPane from '../../components/InfoPanes/RegistryPane'

import './RightContainer.css'

export default () => {

    return (
        <div id='right-container'>
            <HomePane/>
            <DetailsPane/>
            <DayOne/>
            <DayTwo/>
            <DayThree/>
            <RegistryPane/>
        </div>
    )

}