import React, {Fragment} from 'react';
import Arrow from '../arrow/Arrow'
import {Element} from 'react-scroll';

import './Pane.css'

export default () => {

    return (
        <Fragment>
            <div />
            <Element name="dayThree"/>
            <div className='pane'>
                <Arrow direction='up' scrollElement='dayTwo'/>
                <div className='container'>
                    <div className='dayHeader bold'>SUNDAY, AUGUST 11, 2019</div>
                    <div><hr/></div>
                    <div className='day-organizer'>
                        <div className='dayTextMedium bold'>10:00 AM - 12:00 PM</div>
                        <div className='dayTextMedium bold'>Continental Breakfast</div>
                        <div className='dayTextSmall'>Several of you have come far to celebrate with us. Swing by on your way out. We’ll have a bloody mary bar and a full assortment of pastries and light fare.</div>
                    </div>
                </div>
                <Arrow direction='down' scrollElement='registry'/>
            </div>
        </Fragment>)
}