import React, {useCallback} from 'react';
import {scroller} from 'react-scroll';

import './Arrow.css'

export default ({direction, scrollElement}) => {

    const scrollTo = useCallback(() => {
        scroller.scrollTo(scrollElement, {
            duration: 1000,
            delay: 100,
            smooth: "easeInOutQuint"
        })
    }, []);

    const classes = `arrow ${direction}`;
    return (
        <div className='arrow-container' onClick={scrollTo}>
            <div className={classes} />
        </div>
    )
}
