import React, {Fragment} from 'react';
import Arrow from '../arrow/Arrow';
import {Element} from 'react-scroll'

import './Pane.css'

export default () => {

    return (
        <Fragment>
            <div/>
            <Element name="details"/>
            <div id='details' className='pane'>
                <Arrow direction={'up'} scrollElement={'home'} />
                <div id='date'>AUGUST 10, 2019</div>
                <div id='location'>
                    LOCATED AT THE PRIVATE RESIDENCE OF<br/>
                    ERIC & JEANETTE KLEIN<br/>
                    7835 ZANGLE RD NE, OLYMPIA, WA 98506
                </div>
                <Arrow direction={'down'} scrollElement={'dayOne'}/>
            </div>

        </Fragment>
    )
}